import { Alchemy, Network } from "alchemy-sdk";
const Web3 = require("web3")
let web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

const config = {
  apiKey: "BNjQC2zZMyP9962CWvp155oGJ0PER5Ma",
  network: Network.ETH_GOERLI,
};

//const contractABI = require('../contract-abi.json')
//const contractAddress = "0x4C4a07F737Bf57F6632B6CAB089B78f62385aCaE";
const contractAddress = "0x48DbA7ce2C38C070Bf60Db2Fc91E0A4d3e451901";

export const connectWallet = async () => {
    console.log("connectWallet- init");
    if (window.ethereum) {
      try {
        console.log("connectWallet - metamaks instalado");
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "",
          address: addressArray[0],
        };
        console.log("connectWallet - primer address " + addressArray[0] +  ", cantidad de cuentas: " +addressArray.length);
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 Tuvimos un problema connectando tu wallet: " + err.message,
        };
      }
    } 
  };
  export const getCurrentWalletConnected = async () => {
    console.log("getCurrentWalletConnected- init");
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "",
            supportedBrowser: true
          };
        } else {
          return {
            address: "",
            status: "", //"🦊 Connect to Metamask using the top right button.",
            supportedBrowser: true
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
          supportedBrowser: false
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              Para poder continuar necesitas instalar Metamask como extensión en el browser.
              <a className="status" target="_blank" href={`https://metamask.io/download.html`}>
                En este link podes instalarla. 
              </a>
            </p>
          </span>
        ),
        supportedBrowser: false
      };
    }
  };

  export const getOwnedNFT = async (addressOwner) => {
    console.log("getOwnedNFT- init");
    if (addressOwner === "") { 
      return {
          success: false,
          status: "",
          supportedBrowser: true
      }
    } 
    const alchemy = new Alchemy(config);
    try {
      const nfts = await alchemy.nft.getNftsForOwner(addressOwner);
      console.log(nfts);
      return {
          success: true,
          status: "",
          ntfs: nfts
      }
    } catch (error) {
        return {
            success: false,
            status: "😥 Tuvimos un error en obtener tus NFTs: " + error.message
        }
    }
  };

  export const create = async (data) => {
    console.log("create NFT Mint: " + data.address)
    return fetch('https://cool-nougat-d38e92.netlify.app/.netlify/functions/wallet-search/'+data.address)
      .then(res=>res.json())
        .then(res_data=>{ 
          console.log(res_data); 
          if(res_data.length === 0){
            return fetch('https://cool-nougat-d38e92.netlify.app/.netlify/functions/wallets-create', {
              body: JSON.stringify(data),
              method: 'POST'
            }).then(response => {
              return response.json()
            })
          }else{
            console.log("create NFT Mint: " + data.address + " ya estaba pedido con esta address ")
          }
      })
  };