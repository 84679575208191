import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  getOwnedNFT,
  create
} from "./util/interact.js";
import {isMobile, isFirefox, isOpera, isEdge, isChrome, isSafari, isIE} from 'react-device-detect';

const NFTWallet = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [ntfList, setNtfList] = useState([]);
  const [supportedBrowser, setSupportedBrowser] = useState([]);

  useEffect(async () => {
    
    const { address, status, supportedBrowser } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    setSupportedBrowser(supportedBrowser);
    if(address){
      handleNFTs(address);
    }
    addWalletListener();
    
  }, []);

  function addWalletListener() {
    if(isMobile){
      setStatus(
        <p>
          {" "}
          📱{" "}
          Hemos detectado que ingresaste con browser mobile. 
            <a className="status" target="_blank" href={`https://metamask.app.link/dapp/master--bespoke-chimera-a77e7e.netlify.app/`}>
            Necesitamos que uses el browser interno de Metamask siguiendo este Link para conectarte.
            </a>
        </p>
      );  
    }
    if(isIE || isSafari  ) {
      setStatus(
        <p>
          {" "}
          🤦‍♂️{" "}
          Para poder continuar necesitas utilizar un browser que soporte Metamask. 
          <a className="status" target="_blank" href={`https://metamask.io/download.html`}>
              Acá podes ver los browsers soportados. 
          </a> 
        </p>
      );
    } else {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
          if (accounts.length > 0) {
            setWallet(accounts[0]);
            create({address: accounts[0], creation_date: new Date(), minted: false, tx: ""}).then((response) => {
              console.log("Pedido de minteo realizado: " + response)
            }).catch((e) => {
              console.log('An API error occurred', e)
              // Reset to original state
            })
            handleNFTs(accounts[0]);
            setStatus("");
          } else {
            setWallet("");
            removeNFTs();        
            setStatus(
              <p>
                {" "}
                🦊{" "}
                Desconectaste tu wallet de esta DApp, vuelve a conectarte para ver tus NFTs. 
              </p>
            );
          }
        });
      } else {
        setStatus(
          <p>
            {" "}
            🦊{" "}
            Para poder continuar necesitas instalar Metamask como extensión en el browser.
            <a className="status" target="_blank" href={`https://metamask.io/download.html`}>
              En este link podes instalarla. 
            </a>
          </p>
        );
      }
    }
  }

  const connectWalletPressed = async () => {
    if(supportedBrowser){
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);
    }
  };
  
  const handleNFTs = async (xs) => {
    const alchemyResponse= await getOwnedNFT(xs);
    setNtfList(alchemyResponse.ntfs.ownedNfts);
  };

  const removeNFTs = async () => {
    setNtfList([]);
  };

  return (
    <div className="NFTWallet">
      <div>
        <span className="metamask-span">{status}</span>
      </div>
        <div>
          <button className="metamask-button" color="white" onClick={connectWalletPressed}>
            <span className="metamask-span"><svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><path d="M8.49 3.5L1.492.5l-1 3 2 8.5 18.995-.5 2-8-1.5-3-6.499 3H8.49z" fill="#763D16"></path><path d="M6.99 12h10.998v7H6.99v-7z" fill="#333"></path><path fill="#333" d="M7.99 17h5.998v3H7.99z"></path><path d="M1.581 10.664a.609.609 0 0 1 .126.585l-1.484 4.59a.517.517 0 0 0 0 .356l1.296 4.445c.104.334.438.522.752.417l4.452-1.231c.188-.063.397 0 .564.125l.773.626c.021.021.021.021.042.021l1.923 1.336c.104.062.23.104.355.104H13.6c.125 0 .25-.042.355-.104l1.923-1.336c.02 0 .02-.02.042-.02l.773-.627a.663.663 0 0 1 .564-.125l4.452 1.231c.335.084.669-.104.753-.417l1.295-4.445a.517.517 0 0 0 0-.355l-1.483-4.591a.599.599 0 0 1 .125-.585l1.588-7.116a.536.536 0 0 0-.02-.313L23.024.417c-.105-.334-.48-.5-.815-.375l-6.94 2.587a.744.744 0 0 1-.208.042H8.917c-.083 0-.146-.02-.209-.042L1.77.042c-.334-.126-.71.041-.815.375l-.92 2.818a.524.524 0 0 0-.02.313l1.567 7.116zm12.415 3.59l.522-1.085c.063-.126.23-.188.356-.126l1.254.564c.23.104.209.438-.042.522l-1.756.521a.285.285 0 0 1-.334-.396zm-.104-7.534c-.126-.105-.126-.313.02-.397l7.9-5.405a.252.252 0 0 1 .377.125l.982 2.692c.021.042.021.084 0 .147l-1.61 6.198a.226.226 0 0 1-.292.146l-4.347-1.169c-.042 0-.063-.02-.084-.042L13.891 6.72zm-3.825 12.814l.146-1.21c0-.063.042-.126.105-.168l.292-.208c.042-.021.084-.042.126-.042h2.466c.042 0 .104.02.125.042l.293.208c.063.042.083.105.104.167l.126 1.21a.243.243 0 0 1-.23.272h-3.344c-.105 0-.21-.125-.21-.271zM7.85 13.607l1.254-.564a.27.27 0 0 1 .356.126l.522 1.085c.105.208-.104.438-.334.375l-1.777-.521c-.23-.063-.25-.397-.02-.501zM.808 3.86c-.021-.042 0-.105 0-.146l.982-2.672c.063-.146.251-.208.376-.125l7.9 5.405a.244.244 0 0 1 .022.397L7.14 9.015a.159.159 0 0 1-.084.042L2.71 10.226a.226.226 0 0 1-.293-.146L.807 3.86z" fill="#F36D34"></path></svg>&nbsp;&nbsp;
              {walletAddress.length > 0 ? (
                "Connected:" + 
                String(walletAddress).substring(0, 6) 
                + "..." + 
                String(walletAddress).substring(38)
              ) : (
                "Connect with MetaMask"
              )}
            </span>
          </button>

          <br></br>
          
          {walletAddress.length > 0 ? (
            ntfList.length > 0 ? (
                <h2>NFT Collection</h2>
              ) : (
                <span className="metamask-span">Ya recibimos tu pedido de Minteo, en las próximas 24hs estás recibiendo tu primer NFT de Ingenia</span>
              )
          ) : (
            <span className="metamask-span"></span>
          )}



          {ntfList.map(d => (
            <div class="card">
              <div class="card__head">
                <div class="card__product-img">
                  <img src={d.media[0].gateway} alt=""></img>
                </div>
              </div>
              <div class="card__body">
                <a href="#">
                  <h3 class="card__title">{d.title}</h3>
                </a>
                <p class="card__text">{d.description}</p>
                <div class="wrapper">
                  <div class="card__price">
                    <img src="polygon-matic-logo.png" alt="" class="card__icon"></img>
                    <span>Polygon</span>
                  </div>
                  <div class="card__countdown">
                    <img src="icon-clock.svg" alt="" class="card__icon"></img>
                    <span>Minted {d.timeLastUpdated}</span>
                  </div>
                </div>
              </div>
              <div class="card__footer">
                <img src="ingenia-logo.png" alt="" class="card__author-img"></img>
          
                <p class="card__author-name"> Creation of <a href="#"> Ingenia</a></p>
              </div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default NFTWallet;
